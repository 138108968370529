import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { Layout } from '../../components/Layout'
import { components } from '../../slices'

function CareersItem(props) {
  console.log('CareersItem props: ', props)
  const { prismicCareersItem, prismicCareers } = props.data
  const careersItem = prismicCareersItem.data
  // const eventInfoSlice = event.body.find(item => item.slice_type === 'event_info');
  const seo = {
    title: prismicCareersItem.data.seo_title || prismicCareersItem.data.title.text,
    description: prismicCareersItem.data.seo_description,
    keywords: prismicCareersItem.data.seo_keywords,
    no_robots: prismicCareersItem.data.no_robots,
  }
  console.log('seo: ', seo)

  const {title, body} = careersItem
  const cntx = {title, restLink:careersItem.restaraunt_link, titleSectionImage:prismicCareers.data.title_section_image};//restLink:event.restaraunt_link}
  console.log('cntx: ', cntx)
  // console.log('Link to rest: ', event.restaraunt_link)

  const topMenu = props.data.prismicTopMenu || {}
  const footer = props.data.prismicFooter || {}

  // https://developers.google.com/search/docs/advanced/structured-data/job-posting?hl=ru#job-posting-definition
  const job = {
    title: '',
    date: '',
    desc: '',
    organization: {
      name: '',
      url: null,
      logo: null,
    },
    location: {
      locality: '',
      region: '',
      country: '',
    },
    baseSalary: {
      currency: 'USD',
      value: 40.00,
      unit: 'HOUR',
    },
  }

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={seo} job={{}}>
      <SliceZone slices={body} components={components} context={cntx}/>
    </Layout>
  )
}

export default CareersItem

export const query = graphql`
  query($id: String!) {
    prismicCareers {
      data {
        title_section_image {
          url
        }
      }
    }
    prismicCareersItem(id: { eq: $id }) {
      id
      type
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title
        restaraunt_link {
          id
          uid
          link_type
          type
          url
          document {
            ... on PrismicRestaurantDetail {
              id
              data {
                body {
                  ... on PrismicRestaurantDetailDataBodyRestaurantDetail {
                    id
                    slice_type
                    primary {
                      address
                      rest_name
                      logo {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ...PrismicCareersItemVacancyInfo
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`